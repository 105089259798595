import React from 'react';
import { Link } from 'react-router-dom';
import './WhyUs.css';

function WhyUs() {
  return (
    <div className="why-us-container">
      <h1>Why Choose ZipCode AutoFill?</h1>
      
      <section className="hero">
        <h2>Streamline Your Address Management with Unparalleled Efficiency</h2>
        <p>ZipCode AutoFill is the smart solution for businesses looking to optimize their HubSpot CRM with accurate, automated address data.</p>
      </section>

      <section className="benefits">
        <h2>Key Benefits</h2>
        <div className="benefit-grid">
          <div className="benefit">
            <i className="fas fa-tachometer-alt"></i>
            <h3>Increased Efficiency</h3>
            <p>Save countless hours by automating address entry. What used to take minutes now takes seconds.</p>
          </div>
          <div className="benefit">
            <i className="fas fa-check-circle"></i>
            <h3>Enhanced Accuracy</h3>
            <p>Eliminate human error in data entry. Our system ensures precise address information every time.</p>
          </div>
          <div className="benefit">
            <i className="fas fa-sync"></i>
            <h3>Real-Time Updates</h3>
            <p>Address fields are populated instantly as soon as a ZIP code is entered, keeping your data current.</p>
          </div>
          <div className="benefit">
            <i className="fas fa-puzzle-piece"></i>
            <h3>Seamless Integration</h3>
            <p>Designed specifically for HubSpot, our solution integrates flawlessly with your existing workflow.</p>
          </div>
        </div>
      </section>

      <section className="features">
        <h2>Features That Set Us Apart</h2>
        <ul>
          <li>Specialized for 5-digit US ZIP codes, ensuring accuracy for United States addresses</li>
          <li>Customizable field mapping to match your specific HubSpot setup</li>
          <li>Supports both contact and company records in HubSpot</li>
          <li>Populates city, state, county, and country information from a single ZIP code</li>
          <li>Pay-as-you-go model with no monthly fees or commitments</li>
          <li>Secure and compliant with data protection regulations</li>
        </ul>
      </section>

      <section className="features">
        <h2>Perfect for Businesses of All Sizes</h2>
        <p>Whether you're a small business handling hundreds of contacts or a large enterprise managing thousands, ZipCode AutoFill scales to meet your needs:</p>
        <ul>
          <li>E-commerce companies ensuring accurate shipping addresses</li>
          <li>Real estate firms managing property locations</li>
          <li>Marketing agencies running geo-targeted campaigns</li>
          <li>Sales teams organizing territories and leads</li>
          <li>Customer service departments verifying customer information</li>
        </ul>
      </section>

      <section className="testimonial">
        <blockquote>
          "ZipCode AutoFill has revolutionized my address management process. It's saved me countless hours and significantly reduced errors in our database. I have also seen a significant uptick in form submissions since I was able to cut out many unnecessary fields."
        </blockquote>
        <p className="author">- Gian Paul Scorzo, Founder at CARISSIMO</p>
      </section>

      <section className="cta">
        <h2>Ready to Optimize Your HubSpot Experience?</h2>
        <p>Join the businesses already saving time and improving data accuracy with ZipCode AutoFill.</p>
        <Link to="/register" className="cta-button">Get Started Today</Link>
      </section>
    </div>
  );
}

export default WhyUs;
